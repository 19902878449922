import { Observable } from 'rxjs';

import { SupplierDetailsModel } from '../../../model/supplier.model';
import { ContentSource } from '../consts/content-source';

export interface IBirthDate {
  Day: string;
  Month: string;
  Year: string;
}

export interface ICriteria {
  BirthDate: IBirthDate;
  City: string;
  FirstName: string;
  LastName: string;
  SSN: string;
  State: string;
  Street: string;
  ZipCode: string;
}

export interface IEIDVPersonSearchRequest {
  Criteria: ICriteria;
  EIDVName: string;
  EIDVVersion: string;
  InternalID: string;
  UserReference: string[];
}

export interface INormalizedCriteria {
  BirthDate: string;
  City: string;
  FirstName: string;
  LastName: string;
  SSN: string;
  State: string;
  StreetName: string;
  StreetNumber: string;
  ZipCode: string;
}

export interface IDocumentIdentifierInfo {
  DocumentIdentifier: string;
  Source: string;
}

export interface IAdditionalPhoneNumber {
  DocumentIdentifierInfo: IDocumentIdentifierInfo;
  PhoneNumber: string;
  Source: any;
}

export interface IAdditionalPhoneNumbers {
  AdditionalPhoneNumber: IAdditionalPhoneNumber[];
}

export interface IDeath {
  DeathIndicator: string;
}

export interface IHistoricalAddress {
  HistoricalAddressIndicator: string;
}

export interface IKnownAddress {
  City: string;
  County: string;
  DocumentIdentifierInfo: IDocumentIdentifierInfo;
  LastConfirmed: string;
  Source: any;
  State: string;
  Street: string;
  ZipCode: string;
}

export interface IKnownAddresses {
  KnownAddress: IKnownAddress[];
}

export interface IKnownEmailAddress {
  DocumentIdentifierInfo: IDocumentIdentifierInfo;
  EmailAdress: string;
  Source: string;
}

export interface IKnownEmailAddresses {
  KnownEmailAddress: IKnownEmailAddress;
}

export interface IMultipleSSNs {
  MultipleSSNsIndicator: string;
}

export interface IOfacListing {
  OfacListingIndicator: string;
}

export interface ISSNMatchesMultipleIndividuals {
  SSNMatchesMultipleIndividualsIndicator: string;
}

export interface IMatchScore {
  '#text': string;
  '@matchscore': string;
  '@matchtype': string;
}

export interface ISummaryScore {
  '@matchscore': string;
  '@matchtype': string;
}

export interface ISearchRecord {
  BirthDate: IMatchScore;
  City: IMatchScore;
  ContentScore: string;
  ContentSource: string;
  DocumentIdentifier: string;
  FirstName: IMatchScore;
  LastName: IMatchScore;
  MiddleName: string;
  PhoneNumber: string;
  SSN: IMatchScore;
  State: IMatchScore;
  StreetName: IMatchScore;
  StreetNumber: IMatchScore;
  ZipCode: IMatchScore;
}

export type TSearchRecord = ISearchRecord[] | ISearchRecord | undefined;

export interface ISearchRecords {
  SearchRecord: TSearchRecord;
}

export interface ISummary {
  BirthDate?: ISummaryScore;
  City?: ISummaryScore;
  FirstName?: ISummaryScore;
  LastName?: ISummaryScore;
  MiddleName?: ISummaryScore;
  PhoneNumber?: ISummaryScore;
  SSN?: ISummaryScore;
  State?: ISummaryScore;
  StreetName?: ISummaryScore;
  StreetNumber?: ISummaryScore;
  ZipCode?: ISummaryScore;
}

export interface IPersonEntity {
  AdditionalPhoneNumbers: IAdditionalPhoneNumbers;
  Death: IDeath;
  EntityIdentifier: string;
  GroupId: string;
  HistoricalAddress: IHistoricalAddress;
  KnownAddresses: IKnownAddresses;
  KnownEmailAddresses: IKnownEmailAddresses;
  MultipleSSNs: IMultipleSSNs;
  OfacListing: IOfacListing;
  RecordNumber: string;
  SSNMatchesMultipleIndividuals: ISSNMatchesMultipleIndividuals;
  SearchRecords: ISearchRecords;
  Summary: ISummary;
  TotalScore: string;
}

export interface IPersonEntities {
  PersonEntity: IPersonEntity;
}

export interface IEIDVPersonSearchResult {
  EIDVPersonSearchRequest: IEIDVPersonSearchRequest;
  NormalizedCriteria: INormalizedCriteria;
  PersonEntities: IPersonEntities;
}

export interface IEIDVPersonSearchResults {
  EIDVPersonSearchResult: IEIDVPersonSearchResult;
}

export interface IStatus {
  StatusCode: string;
  SubStatusCode: string;
}

export interface INs2EIDVPersonSearchResponse {
  '@xmlns:ns2': string;
  EIDVPersonSearchResults: IEIDVPersonSearchResults;
  Status: IStatus;
}

export interface IRealTimeValidatorDTO {
  result: {
    'ns2:EIDVPersonSearchResponse': INs2EIDVPersonSearchResponse;
  };
  timestamp: string;
  status?: string;
}

export interface IUWOwner {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  SSN?: string;
  address: string;
  address2: string;
  city: string;
  country: string;
  stateCode: string;
  zipPostalCode: string;
  shares?: any;
  mainOwner: boolean;
  phoneNumber?: any;
  loginKey: string;
  email: string;
}

export interface ICorelation {
  correlation_id: string;
}

export interface IRealTimeValidationRow {
  row: string;
  value: string;
  FieldName: string;
  UserInput: string;
  ExperianCreditHeader: string;
  EquifaxCreditHeader: string;
  TransUnion: string;
  UtilityListing: string;
  ClearIDStatus: string;
}

export interface ITaskInput {
  supplier_key: string;
  person_id: string;
  first_name: string;
  last_name: string;
  birth_day: string;
  birth_month: string;
  birth_year: string;
  city: string;
  ssn: string;
  state: string;
  street: string;
  zip_code: string;
}

export interface ITRTaskDTO {
  task_name: string;
  task_input: ITaskInput;
}

export interface ITRDataError {
  error: string;
  message: string;
}

export interface INamedColumns {
  [ContentSource.EXPERIAN_CREDIT_HEADER]: INormalizedCriteria;
  [ContentSource.EQUIFAX_CREDIT_HEADER]: INormalizedCriteria;
  [ContentSource.TRANS_UNION]: INormalizedCriteria;
  [ContentSource.UTILITY_LISTING]: INormalizedCriteria;
}

export interface IRealTimeValidator {
  getRealTimeValidation(supplierKey: string, loginKey: string): Observable<IRealTimeValidatorDTO>;
  requestValidation(supplierKey: string, loginKey: string, taskDTO: ITRTaskDTO): Observable<ICorelation>;
  getValidationStatus(corelationId: string): Observable<any>;
}

export interface IRealTimeInputFactory {
  fromSupplier(supplier: SupplierDetailsModel, whichOwner?: number): ITRTaskDTO | ITRDataError;
}

export interface IOnReady {
  onReady(): void;
}
